import React from "react"
import { Layout } from "../components/common"
import bandWave from "../components/assets/images/bg/banner4-wave1.svg"
import waterMark2 from "../components/assets/images/bg/watermark2-bg.png"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
const NotFound = () => (
  <Layout>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    {/* <Seo title="404: Not found" location="/404" /> */}
    <div className="banner-section4" style={{ minHeight: "60vh" }}>
      <img
        src={bandWave}
        className="banner4-wave1 img-fluid"
        alt="background banner"
      />
      <img
        src={waterMark2}
        className="banner4-wave2 img-fluid"
        alt="Wbackground banner 2"
      />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div
              className="section-title3 primary4 text-cener"
              style={{ marginBottom: "12px" }}
            >
              <span>-Oops - Page not found-</span>
              <p>No page found for the link you have clicked.</p>
              <div className="button-group gap-5">
                <Link to="/">
                  <div className="eg-btn btn--primary4 btn--lg">Home</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFound
